body {
  margin: 0;
  font-size: 100%;
  padding: 10px;
}

.radiobox__wrapper label:before {
  border-color: #000;
}

.radiobox__wrapper.checked label:before {
  background-color: #000 !important;
}

input {
  -webkit-appearance: none;
  padding: 6% 6%;
  border: 1px solid #dce0e0;
  color: black;
  /* font-size: 16px; */
  width: 77%;
}

:global .icon {
  color: #7eb4ef;
}

.my-button {
  font-size: 14px;
}

.formborder {
  min-height: 1000px;
  background-color: #e5e5e5;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 650px) {
  .formborder {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  .mapRowStyle {
    display: flex;
  }
}

.chartdisplayborder {
  background-color: #e5e5e5;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 1000px) {
  .chartdisplayborder {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
}

.chartRowWrapper {
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media screen and (max-width: 820px) {
  .chartRowWrapper {
    flex-direction: column;
  }
  .chart {
    width: 100%;
    padding-top: 10%;
  }
}
.chart {
  width: 100%;
}

.chartboarder {
  margin-left: 2%;
  margin-right: 2%;
}

.mapRowStyle {
  align-items: flex-start;
  justify-content: space-between;
  padding: 2%;
  font-size: 14px;
}

.my-button__red {
  color: #fff;
  border-radius: 2px;
  background: #f78f8f;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}

.leaflet-container {
  width: 100%;
  height: 65vh;
}

.sub-section-title-wrapper {
  padding: 2%;
}

.sub-section-title {
  background: #7eb4ef;
  color: #fff;
  padding: 30px;
  font-size: 32px;
}

.block {
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  padding: 2%;
}
